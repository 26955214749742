import { Example } from "./Example";

import styles from "./Example.module.css";

const DEFAULT_EXAMPLES: string[] = [
    "I am onboard AIDAperla and a coffee machine just failed. On the label, I found 03.8900.0200. Do I still have warranty?",
    "I'm on Costa Fortuna, we recently painted an area and we suspect a paint failure. The paint is MAPECOAT GUARD 100/A RAL7040. Can I claim this under warranty?",
    "A monitor just failed onboard AIDAluna. It's a Dell monitor built into our CCTV system. Can we file a warranty claim?"
];

const GPT4V_EXAMPLES: string[] = [
    "I am onboard AIDAperla and a coffee machine just failed. On the label, I found 03.8900.0200. Do I still have warranty?",
    "I'm on Costa Fortuna, we recently painted an area and we suspect a paint failure. The paint is MAPECOAT GUARD 100/A RAL7040. Can I claim this under warranty?",
    "A monitor just failed onboard AIDAluna. It's a Dell monitor built into our CCTV system. Can we file a warranty claim?"
];

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                <li key={i}>
                    <Example text={question} value={question} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
